import "../styles/menu_tab.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMoon, faSun, faX} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/imgs/giwasco_logo.png";
import { useState } from "react";
import QuickLinks from "./quick_links";

export default function MenuTab (props) {

    return (
        <div className= "menutab">
            <div className="navigation">
                <div className="catchphrase">
                    <p>One Drop, One Life, One Earth</p>
                </div>
                <QuickLinks />
                <div className="logo">
                    <img src={logo} alt="" />
                    <h1>Githunguri Water & Sanitation Company Ltd.</h1>
                </div>
                <div className="close">
                    <FontAwesomeIcon icon={faX} onClick={() => props.setToggleMenu(!props.toggleMenu)}/>
                </div>
            </div>
        </div>
    );
}