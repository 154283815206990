import { useEffect, useState, useRef } from "react";
import "../styles/contactus.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import UserInput from "../components/UserInput";

export default function ContactUs (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  const mainoffice = {lat: -1.0573252051583522, lng: 36.77738982259471};
  const migutaoffice = {lat: -1.0709323881305357, lng: 36.82480339783219};
  const kagweoffice = {lat: -1.0038785297044281, lng: 36.741532873373984};

  const rfEmail = useRef();
  const rfName = useRef();
  const rfMessage = useRef();
  // const [error, setError] = useState("");
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [body, updateBody] = useState({
    Name: null,
    Email: null,
    Password: null,
  });

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const sendmessage = () => {
    let d = body;
    d.Name = rfName.current.getValue();
    d.Email = rfEmail.current.getValue().toLowerCase().trim();
    d.Message = rfMessage.current.getValue();
    updateBody(d);
    setError("");

    if (!validateEmail(body.Email))
      return setError("Please Enter a Valid Email Address!");

    if (validateEmail(body.Email)) {
      console.log(JSON.stringify(body));
      
      setIsLoading(true);
      fetch("/api/enquiries/new", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          console.log(data);
          
          if (data.success) {
            localStorage.setItem("nimda_giwasco", data.token);
            setError(data.success);
            setIsLoading(false);
            window.location.href = "/admin";
          } else {
            setIsLoading(false);
            setError(data.error);
          }
        })
        .catch((err) => {
          console.log(err);
          
          setIsLoading(false);
          setError("Login failed");
        });
    }
  };
  
  return (
    <div className="body">
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="contactus" id="contactus">
        <div className="centerheading">
          <h2 className="decorated-heading">Contact Us</h2>
        </div>
        <hr />
        <div className="contactInfo">
            <div className="centerheading">
                <h2>Our Offices</h2>
            </div>
            <div className="offices">
                <div className="office">
                  <div className="officedetails">
                    <h3>Head Office</h3>
                    <p>Githunguri Municipality, Kiambu, Kenya.</p> 
                    <p>Next to Githunguri Level 5 Hospital,</p>
                    <p>along Githunguri-Kambaa Road,</p>
                    <p>Phone: +254700484034</p>
                    <p>Email: info@githunguriwater.com</p>
                  </div>
                  <LocationDiv office={mainoffice} />
                </div>
                <div className="office">
                  <div className="officedetails">
                    <h3>Kagwe Office,</h3>
                    <p>Kagwe town, </p>
                    <p>Kiambu, Kenya.</p>
                    <p>Phone: +254731554433</p>
                    <p>Email: info@githunguriwater.com</p>
                    <p>.</p>
                  </div>
                  <LocationDiv office={kagweoffice} />
                </div>
                <div className="office">
                  <div className="officedetails">
                    <h3>Miguta Office</h3>
                    <p>Miguta,</p>
                    <p>Kiambu, Kenya.</p>
                    <p>Phone: +254731554433</p>
                    <p>Email: info@githunguriwater.com</p>
                    <p>.</p>
                  </div>
                  <LocationDiv office={migutaoffice} />
                </div>
            </div>
        </div>
        <hr/>
        <div className="sendmessage-container">
            <div className="centerheading">
                <h2>Get in Touch</h2>
            </div>
            <UserInput ref={rfName} label="Full Name" type="text" id="name" placeholder="Enter your name" />
            <UserInput ref={rfEmail} label="Email" type="email" id="email" placeholder="Enter your email" />
            <UserInput ref={rfMessage} label="Message" type="textarea" id="description" placeholder="Enter your message" />
            <button type="submit" className="submit-btn" onClick={() => sendmessage()}>
              Submit
            </button>
        </div>
        <hr />
      </div>
      <Footer />
    </div>
  );
}

function LocationDiv (props) {

  const handleMapClick = (lat, lng) => {
    
    const googleMapsUrl = `https://www.google.com/maps?q=${lat},${lng}`;
    window.open(googleMapsUrl, '_blank');
  };

  return (
    <div>
      <div className="map-container">
        <iframe
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyCKbk3jv0yEhk6QKnMwYj_c4o8A4MR01UM&q=${props.office.lat},${props.office.lng}`}
          width="600"
          height="400"
          allowFullScreen
          loading="lazy"
          title="Google Maps"
        ></iframe>
      </div>
      <button className="view-map-button" onClick = { () => handleMapClick(props.office.lat, props.office.lng)}>
        View on Google Maps
      </button>
    </div>
  )
}