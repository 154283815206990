import "../styles/dropdown.scss";
import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown} from "@fortawesome/free-solid-svg-icons";

export default function Dropdown(props) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const closeDropdown = () => {
    setIsOpen(false);
  };

  return (
    <div 
      className = {props.isSlightlyScrolled ? "dropdownContainer" : "dropdownContainer nobg"}
      onMouseEnter={toggleDropdown} 
      onMouseLeave={closeDropdown}
    >
        <div className="textDropdown">
            <a onClick={toggleDropdown}>
                {props.title}
            </a>
            <FontAwesomeIcon icon={faAngleDown} />
        </div>
      {isOpen && (
        <div className="dropdownMenu">
          {props.items && Object.entries(props.items).map(([item, value], index) => <a href={value}className="menuItem">{item}</a>)}
        </div>
      )}
    </div>
  );
}

// const styles = {
//   dropdownContainer: {
//     position: 'relative',
//     display: 'inline-block',
//   },
//   dropdownMenu: {
//     position: 'absolute',
//     top: '100%',
//     left: '0',
//     backgroundColor: '#fff',
//     boxShadow: '0 8px 16px rgba(0,0,0,0.2)',
//     zIndex: 1,
//     width: 'fit-content',
//   },
//   menuItem: {
//     display: 'block',
//     padding: '12px 16px',
//     textDecoration: 'none',
//     color: '#333',
//   },
// };
