import { useEffect, useState, useRef } from "react";
import "../../styles/stafflogin.scss";
import Header from "../../components/header";
import Footer from "../../components/footer"
import MenuTab from "../../components/menu_tab";
import Banner from "../../components/banner";
import Button from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import UserInput from "../../components/UserInput";
import UserSelect from "../../components/UserSelect";

export default function CreateStaffUser (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const name = useRef();
  const gender = useRef();
  const email = useRef();
  const phone = useRef();
  const position = useRef();
  const department = useRef();
  const role = useRef();
  const password = useRef();
  const cpassword = useRef();

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const createUser = () => {
    const body = {
      Name: titleCase(name.current.getValue().trim()),
      // Gender: gender.current.getValue(),
      Phone: phone.current.getValue(),
      Email: email.current.getValue().toLowerCase().trim(),
      Department: titleCase(department.current.getValue()),
      Position: titleCase(position.current.getValue()),
      Password: password.current.getValue(),
      CPassword: cpassword.current.getValue(),
      Role: role.current.getValue(),
    };

    setError("");

    const validateForm = () => {
      console.log(body.Phone.length);
      
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      if (
        !confirmPassword(body.Password, body.CPassword)
      ) {
        result = false;
        setError("Passwords do not match!");
        setLoading(false);
        return result;
      }
      // if (
      //   !validatePassword(body.Password)
      // ) {
      //   result = false;
      //   setError("Password must be at least 6 characters!");
      //   setLoading(false);
      //   return result;
      // }

      const phonenumberlengths = (len) => {
        if (len === 10 || len === 13) {
          return true;
        }
        else {
          return false;
        }
      }

      if (!body.Phone || !phonenumberlengths(body.Phone.length)) {
        result = false;
        setError("Enter a valid phone number");
        setLoading(false);
        return result;
      }
      if (!body.Name) {
        result = false;
        setError("Name is reqired!");
        setLoading(false);
        return result;
      }
      if (!body.Department) {
        result = false;
        setError("Department field is required!");
        setLoading(false);
        return result;
      }
      if (!body.Position) {
        result = false;
        setError("Position field is required!");
        setLoading(false);
        return result;
      }
      if (!body.Role) {
        result = false;
        setError("Role is required!");
        setLoading(false);
        return result;
      }
      return result;
    };

    if (validateForm() && validatePassword(body.Password)) {
      setLoading(true);
      fetch(`/api/auth/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          console.log(data);
          
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              window.location.href = "/stafflogin";
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const confirmPassword = (password, cpassword) => {
    return cpassword === password;
  };

  // Password validation function
  const validatePassword = (password) => {
    const minLength = 12;
    const specialChars = /[!@#$%^&*(),.?":{}|<>]/g;
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChars = password.match(specialChars) && password.match(specialChars).length >= 2;

    // Check all conditions and build error messages
    if (password.length < minLength) {
        setError("Password must be at least 12 characters long.");
        return false;
    }
    if (!hasUppercase) {
        setError("Password must contain at least one uppercase letter.");
        return false;
    }
    if (!hasLowercase) {
        setError("Password must contain at least one lowercase letter.");
        return false;
    }
    if (!hasNumber) {
        setError("Password must contain at least one number.");
        return false;
    }
    if (!hasSpecialChars) {
        setError("Password must contain at least 2 special characters (e.g., !@#$%^&*).");
        return false;
    }
    
    // All conditions met
    return true;
};

  
  return (
    <div className="body">
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="login-page">
        <div className="login-container">
          <h2>Create Staff User</h2>
          <form onSubmit={(e) => {
              e.preventDefault();
            }}>
            <UserInput ref={name} label="Full Name" type="text" id="name" placeholder="Enter your name" />
            <UserInput ref={email} label="Email" type="email" id="email" placeholder="Enter your email" />
            <UserInput ref={phone} label="Phone" type="phone" id="phone" placeholder="Enter your phone number" />
            <UserInput ref={position} label="Position" type="text" id="position" placeholder="Enter your position" />
            <UserSelect ref={department} label="Department" data = {["Commercial", "Finance", "HR/Admin", "IT", "Management", "Procurement", "Technical",]} />
            <UserSelect ref={role} label="Role" data = {["Admin", "Communication", "Commercial", "Finance", "HR", "IT", "Management", "Procurement", "Technical"]} />
            <UserInput ref={password} label="Password" type="password" id="passwordE" placeholder="Enter your password" />
            <UserInput ref={cpassword} label="Confirm Password" type="password" id="password" placeholder="Re-enter your password" />
            <h6>{error}</h6>
            <button type="submit" className="login-btn" onClick = { () => createUser()} >
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}