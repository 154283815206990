import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

export default function ProtectedRoute({
  component: Component,
  ...restOfProps
}) {
  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {
    // const token = localStorage.getItem("gdfhgfhtkn");
    const token = localStorage.getItem("nimda_giwasco");
    if (token) {
      try {
        let decoded = jwtDecode(token);
        if (Date.now() >= decoded.exp * 1000) {
          setIsAuthenticated(false);
        } else {
          setIsAuthenticated(true);
        }
      } catch (error) {
        setIsAuthenticated(false);
      }
    } else {
      setIsAuthenticated(false);
    }
  }, []);

  const logout = () => {
    fetch("/api/auth/logout", {
      method: "get",
      credentials: "include",
    })
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        setIsAuthenticated(false);
        localStorage.removeItem("nimda_giwasco");
        window.location.href = "/stafflogin";
      })
      .catch((error) => {
        window.location.href = "/stafflogin";
      });
  };

  return isAuthenticated ? (
    <Component {...restOfProps} />
  ) : (
    <Navigate to="/stafflogin" replace />
  );
}
