import { useEffect, useState } from "react";
import "../styles/aboutus.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import servicearea from "../assets/imgs/maps/servicearea.png"
import Button from "../components/button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTint } from '@fortawesome/free-solid-svg-icons';

export default function AboutUs (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  return (
    <div className="body">
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="image">
        <div>
          <p>About Githunguri Water and Sanitation Company</p>
        </div>
      </div>
      <div className="aboutus" id="aboutus">
        <div className="centerheading">
          <h2 className="decorated-heading">Who we are</h2>
        </div>
        <div className="aboutInfo">
            <p>Githunguri Water and Sanitation Company Limited (GIWASCO) is a Water Service Provider operating in Kiambu County 
              in line with Water Act 2002 and was incorporated on the 24th April, 2007 under the company’s act cap 486 and 
              appointed agent of Athi Water Services Board (AWSB) under an SPA to provide efficient, effective good quality and 
              affordable water and sanitation services within Githunguri and lower Lari districts covering 244 km<sup>2</sup>. The company 
              started operations on October 1st 2007.</p>
              <p>In October 2013 the Company changed its Memos and Articles of Association in line with Constitution of Kenya 
                2010 to be an agent of County Government of Kiambu as water services provision was a devolved function.</p>

        </div>
        <hr />
        
        {/* <div className="heading">
          <h2 className="decorated-heading">Vision</h2>
        </div>
        <div className="aboutInfo">
            <p>To be the number one water service provider in Kiambu County.</p>
        </div>
        <hr />
        <div className="heading">
          <h2 className="decorated-heading">Mision</h2>
        </div>
        <div className="aboutInfo">
            <p>To provide effective, efficient, safe and Sustainable water and sanitation services 
              to the satisfaction of our internal and external customers</p>
        </div> */}
        {/* <hr /> */}

        <div className="vision-mission-container">
          <div className="box vision-box">
            <h2>Our Vision</h2>
            <p>To be the number one water service provider in Kiambu County.</p>
          </div>
          <div className="box mission-box">
            <h2>Our Mission</h2>
            <p>
            <p>To provide effective, efficient, safe and Sustainable water and sanitation services to 
              the satisfaction of our internal and external customers</p>
            </p>
          </div>
        </div>
        <hr />
        
        <div className="service_area">
          <div className="heading">
            <h2 className="decorated-heading">Service Area</h2>
          </div>
          <div className="map">
              <img src={servicearea} alt="" />
          </div>
        </div>
        {/* <div className="heading">
          <h2 className="decorated-heading">Core Values</h2>
        </div>
        <div className="aboutInfo">
            <ul>
              <li>Strategic Innovation</li>
              <li>Time</li>
              <li>Integrity</li>
              <li>Climate Adaptation</li>
              <li>Customer Focus</li>
              <li>Teamwork</li>
            </ul>
        </div> */}
        <hr />

        <div className="vision-values-container">
          <div className="box values-box">
            <h2>Core Values</h2>
            <ul className="fancy-list">
              <li><FontAwesomeIcon icon={faCheckCircle} />Strategic Innovation</li>
              <li><FontAwesomeIcon icon={faCheckCircle} />Customer Focus</li>
              <li><FontAwesomeIcon icon={faCheckCircle} />Good Governance</li>
              <li><FontAwesomeIcon icon={faCheckCircle} />Time</li>
              <li><FontAwesomeIcon icon={faCheckCircle} />Integrity</li>
              <li><FontAwesomeIcon icon={faCheckCircle} />Teamwork</li>
              <li><FontAwesomeIcon icon={faCheckCircle} />Climate Adaptation</li>
            </ul>
          </div>
          <div className="box values-box">
            <h2>Our Commitment</h2>
            <ul className="fancy-list">
              <li></li>
              <li><FontAwesomeIcon icon={faTint} /> We commit to provide a level of customer care, which will at the very least, meet our customers’ expectations.</li>
              <li><FontAwesomeIcon icon={faTint} /> To act in the best interest of our customers and continually improve our standards of quality in every aspect of our services.</li>
              <li><FontAwesomeIcon icon={faTint} /> All Company staff pledge to our current and future customers the highest quality service.</li>
            </ul>
          </div>
        </div>
        <hr />

        {/* <div className="heading">
          <h2 className="decorated-heading">Our Commitment</h2>
        </div>
        <div className="aboutInfo">
            <ul>
              <li>We commit to provide a level of customer care, which will at the very least, meet our customers’ expectations.</li>
              <li>To act in the best interest of our customers and continually improve our standards of quality in every aspect of our services.</li>
              <li>All Company staff pledge to our current and future customers the highest quality service.</li>
            </ul>
        </div> */}
        <hr />
      </div>
      <Footer />
    </div>
  );
}