import React from "react";
import "../../styles/tendercareerstable.scss";
import open from "../../assets/imgs/open.png";
import closed from "../../assets/imgs/closed.png";

export default function CareersTable(props) {

    const now = new Date(); // Get the current date and time

    return(
      <div className="tendercareer-posting">
      <table className="tendercareer-posting__table">
        <thead>
          <tr>
            <th>No.</th>
            <th>Job Title</th>
            <th>Grade</th>
            <th>Job Type</th>
            <th>Department</th>
            <th>Date Posted</th>
            <th>Deadline</th>
            <th>View Details</th>
          </tr>
        </thead>
        <tbody>
          {props.data && props.data?.map((job, index) => (
            <tr key={index+1}>
              <td data-label="No.">{index + 1}</td>
              <td data-label="Job Title">{job.Position}</td>
              <td data-label="Location">{job.Grade}</td>
              <td data-label="Job Type">{job.Type}</td>
              <td data-label="Job Department">{job.Department}</td>
              <td data-label="Date Opened">{job.DateOpened?.toString().split("T")[0]}</td>
              <td data-label="Deadline">{job.Deadline.toString().split("T")[0]}</td>
              <td data-label="View Details">
                <a
                  href={`/api/uploads/${job.File}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="tendercareer-posting__link"
                >
                  View File
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
    );
}