import React from "react";
import "../../styles/tendercareerstable.scss";
import open from "../../assets/imgs/open.png";
import closed from "../../assets/imgs/closed.png";

export default function TenderTable(props) {

    const now = new Date(); // Get the current date and time

    return(
        <div className="tendercareer-posting">
        <table className="tendercareer-posting__table">
          <thead>
            <tr>
              <th>No.</th>
              <th>Status</th>
              <th>Title</th>
              <th>Type</th>
              <th>Description</th>
              <th>View</th>
              <th>Deadline</th>
            </tr>
          </thead>
          <tbody>
            {props.data && props.data?.map((tender, index) => (
              <tr key={index + 1}>
                <td data-label="No.">{index + 1}</td>
                <td data-label="Status">{new Date(tender.Deadline) > now ? <img src={open} alt="" /> : <img src={closed} alt="" />}</td>
                <td data-label="Title">{tender.Title}</td>
                <td data-label="Type">{tender.Type}</td>
                <td data-label="Description">{tender.Description}</td>
                <td data-label="View Details">
                  <a
                    href={`/api/uploads/${tender.File}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tendercareer-posting__link"
                  >
                    View File
                  </a>
                </td>
                <td data-label="Deadline" className="deadline">{tender.Deadline.toString().split("T")[0]}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
    );
}