import React from "react";

export default class UserInput extends React.Component {
  constructor(props) {
    super(props);
    this.input = React.createRef();
    this.getValue = this.getValue.bind(this);
  }

  getValue() {
    return this.input.current.value;
  }

  render() {
    return (
      <div className="form-group">
        <label>{this.props.label}</label>
        {this.props.id === "passwordE" && 
          <div className="passwordinfo">
            <p>Passwords must contain:</p>
            <ul>
              <li>At least 12 characters;</li>
              <li>At least one uppercase letter;</li>
              <li>At least one lowercase letter;</li>
              <li>At least one number;</li>
              <li>at least 2 special characters (e.g., !@#$%^&*).</li>
            </ul>
          </div>
        }
        {this.props.type === 'textarea' ? 
        <textarea
          id={this.props.id}
          ref={this.input}
          type={this.props.type}
          placeholder={this.props.placeholder}
          required
          autoComplete="off"
        />
        :
        <input
          id={this.props.id}
          ref={this.input}
          type={this.props.type}
          placeholder={this.props.placeholder}
          required
          autoComplete="off"
        />}
      </div>
    );
  }
}
