import { useEffect, useState } from "react";
import "../styles/newsevents.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import PostItem from "../components/post_item";
import newconnapplicationform from "../assets/docs/newconnectionform.pdf";
import cis from "../assets/imgs/cis.png";
import pe from "../assets/imgs/publicparticipation.jpeg";
import waterinterruptiongatamaiyu from "../assets/imgs/waterinterruptiongatamaiyu.jpg";
import waterrestoredgatamaiyu from "../assets/imgs/waterrestoredgatamaiyu.jpg";

export default function NewsEvents (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);

  // const downloadFile = () => {
  //   // Create a temporary link element
  //   const link = document.createElement('a');
  //   link.href = "/newconnectionform.pdf"; // Path to the file in the public folder
  //   link.download = 'application-form.pdf'; // Filename for the downloaded file
  //   document.body.appendChild(link); // Append link to the body
  //   link.click(); // Programmatically click the link to trigger the download
  //   document.body.removeChild(link); // Remove the link from the document
  // };

  const openFile = () => {
    const fileUrl = newconnapplicationform; // Path to the file in the public folder
    window.open(fileUrl, '_blank'); // Open the file in a new tab
  };
  
  return (
    <div className="body">
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="image2">
        <div id="newconnection">
          <p>News & Events</p>
        </div>
      </div>
      <div className="newsevents" id="newsevents">
        <div className="centerheading">
        <h5  className="decorated-heading">Be on the know...</h5>
        </div>
        <div className="news">
          <PostItem image={cis} title="Customer Identification Survey" details="We are currently conducting a Customer Identification Survey to enhance our services. By updating your details, you help us respond quickly to your needs and improve service reliability." date="Ongoing" />
          {/* <PostItem image={waterinterruptiongatamaiyu} title="Temporary Water Supply Interruption" details="We regret to inform residents of <b>Kamunyuini in Nyanduma Ward </b> that there is temporary water supply interruption due to the ongoing mau-mau road works affecting the entire <b>Kagwe, Kiambururu, Mitundu, Kamuchege, Miirano </b> and surrounding areas. <br/><br/>Our technical team is currently on-site working to restore the supply as quickly as possible.
We apologize for the inconvenience and appreciate your patience as we work to resolve this issue. <br/><br/>Further updates will be provided as soon as possible.
<br/>Thank you for your understanding." date="26 Oct 2024" /> */}
          <PostItem 
                image={waterrestoredgatamaiyu} 
                title="Water Supply Restored" 
                details="We are pleased to inform residents of <b>Kamunyuini in Nyanduma Ward</b> that the water supply has been restored following the resolution of issues caused by the ongoing mau-mau road works. <br/><br/>Our technical team has worked diligently to ensure water services are back to normal for all affected areas, including <b>Kagwe, Kiambururu, Mitundu, Kamuchege, Miirano</b>, and surrounding regions. <br/><br/>We appreciate your patience and understanding during this time and thank you for your continued support. 
              <br/>For further updates, please stay connected with us." 
                date="29 Oct 2024" 
              />
          <PostItem image={pe} title="Public Engagement" details="We will be conducting a public engagement at Thakwa. All stakeholders and our esteemed customers around Thakwa are invited to give their suggestions to help us respond quickly to your needs and improve service reliability." date="Sunday, 20th Oct 2024" />
        </div>
      </div>
      <Footer />
    </div>
  );
}