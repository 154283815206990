import React from 'react';
import { useEffect, useState } from "react";
import '../styles/statement.scss';
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import MD from "../assets/imgs/management/MD.jpg"
import personicon from "../assets/imgs/personicon.jpg";
import mdstatement from "../assets/imgs/management/mdstatement.JPG";
import Godfrey from "../assets/imgs/board/Godfrey.jpg";

export default function Statement (props) {

    const [toggleMenu, setToggleMenu] = useState(false);

    console.log(window.location.pathname.split("/")[2]);

    const target = window.location.pathname.split("/")[2];

    return (
        <div>
            <Banner />
            <Header toggleMenu={toggleMenu} 
                setToggleMenu={setToggleMenu}
            />
            {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
            setToggleMenu={setToggleMenu}
            />}
            { target === "md" ? 
            <div>
                <div className="image">
                    <div>
                        <p>Managing Director's Statement</p>
                    </div>
                </div>
                <div className="statement-container">
                <div className="statement-image-container">
                    <img
                    src={mdstatement} 
                    alt="MD"
                    className="statement-image"
                    />
                </div>
                <div className="statement-text">
                    <h2>Managing Director's Statement</h2>
                    <h4>Welcome to Githunguri Water and Sanitation Company!</h4>
                    <p>
                        As the Managing Director of Githunguri Water, I am honored to lead an organization dedicated
                        to delivering safe, clean, and reliable water to communities we serve. Water is an essential
                        resource, and our commitment to providing sustainable water solutions is at the heart of
                        everything we do.
                    </p>
                    <p>
                        In a world where climate change and population growth present growing challenges, our role has
                        never been more critical. We recognize the importance of safeguarding water resources, and we
                        are committed to investing in innovative technologies, improving infrastructure, and fostering
                        sustainable practices to ensure a resilient water supply for generations to come.
                    </p>
                    <p>
                        At Githunguri Water, we hold ourselves to the highest standards of environmental stewardship
                        and corporate responsibility. Through transparent governance, a focus on customer satisfaction,
                        and continuous improvement, we strive to exceed expectations. Every day, our dedicated team of
                        professionals works tirelessly to deliver world-class water services while also minimizing our
                        environmental footprint.
                    </p>
                    <p>
                        We are also deeply engaged with the communities we serve, working in partnership with local
                        authorities, businesses, and residents to create sustainable water management strategies. Whether
                        through educational programs, conservation initiatives, or customer support, we aim to empower
                        our customers with the knowledge and tools they need to use water efficiently.
                    </p>
                    <p>
                        Our commitment to excellence is unwavering, and we will continue to adapt and innovate to
                        meet the changing needs of our customers and the environment. We value the trust you place in
                        us, and we are proud to play a role in securing a sustainable water future for all.
                    </p>
                    <p>
                        Thank you for visiting our website and for your continued support.
                    </p>
                    <p>
                        Warm Regards,
                    </p>
                    <h3>Dr. Francis Kahuha</h3>
                    <h4>Managing Director.</h4>
                </div>
                </div>
            </div> 
            : 
            <div>
                <div className="image">
                    <div>
                        <p>Chairman's Statement</p>
                    </div>
                </div>
                <div className="statement-container">
                    <div className="statement-image-container">
                        <img
                        src={Godfrey} 
                        alt="Chair"
                        className="statement-image"
                        />
                    </div>
                    <div className="statement-text">
                        <h2>Chairman's Statement</h2>
                        <h4>Welcome to Githunguri Water and Sanitation Company!</h4>
                        <p>
                            As Chairman of the Board at Githunguri Water, it is my privilege to oversee an organization that
                            plays such a vital role in providing one of the most essential resources—clean and reliable water.
                            Our company is deeply committed to ensuring that every drop of water that reaches your home
                            or business meets the highest standards of quality, safety, and sustainability.
                        </p>
                        <p>
                            In an era of growing environmental concerns and increasing global water challenges, we
                            recognize the importance of responsible stewardship. We have built our company on a
                            foundation of innovation, community engagement, and operational excellence. As we look to the
                            future, we remain steadfast in our vision to lead the way in sustainable water management,
                            ensuring that future generations have access to the water resources they need.
                        </p>
                        <p>
                            The Board of Directors is committed to guiding Githunguri Water with integrity, transparency,
                            and accountability. Our governance model is designed to uphold the long-term interests of all our
                            stakeholders—our customers, employees, shareholders, and the communities we serve. We
                            believe that by aligning our business goals with environmental and social responsibility, we can
                            achieve success that benefits both our company and the planet.
                        </p>
                        <p>
                            I am proud to work alongside our Managing Director, executive team, and dedicated employees
                            who are passionate about delivering exceptional service, embracing innovation, and continually
                            improving our systems and infrastructure. We are making strategic investments to enhance the
                            resilience of our water systems, adapt to climate-related risks, and reduce our environmental
                            footprint.
                        </p>
                        <p>
                            Looking ahead, our focus remains on sustainable growth, improving customer experience, and
                            advancing our leadership in the water industry. We are confident that through continued
                            collaboration with our stakeholders and a forward-thinking approach, we can meet the challenges
                            of today while securing a better tomorrow.
                        </p>
                        <p>
                            On behalf of the Board, I thank you for your trust and support. Together, we will continue to
                            deliver on our commitment to sustainability, innovation, and excellence.
                        </p>
                        <p>
                            Sincerely,
                        </p>
                        <p></p>
                        <h3>Godfrey Kigochi</h3>
                        <h4>Board Chairman.</h4>
                    </div>
                </div>
            </div>}
            <Footer />
        </div>
    );
};
