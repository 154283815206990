import { useEffect, useState } from "react";
import "../styles/home.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import PostItem from "../components/post_item";
// import allstaffmeeting from "../assets/imgs/staffmeeting.jpeg"
import cis from "../assets/imgs/cis.png";
import pe from "../assets/imgs/publicparticipation.jpeg";
import QuickServices from "../components/quickservices";
import PartnerCarousel from "../components/partnercarousel";
import waterinterruptiongatamaiyu from "../assets/imgs/waterinterruptiongatamaiyu.jpg";
import waterrestoredgatamaiyu from "../assets/imgs/waterrestoredgatamaiyu.jpg";

export default function HomePage (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  
  return (
  <div>
    <Banner />
    <Header toggleMenu={toggleMenu} 
      setToggleMenu={setToggleMenu}
    />
    {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
      setToggleMenu={setToggleMenu}
    />}
    <div className="home" id="home">
      <div className="landingscreen">
          <QuickServices />
          {/* {toggleQuickServices ? <QuickServices /> : 
          <div className="content">
              <div className="techimage">
                  <p>.</p>
                  <p>.</p>
                  <h3>Welcome to Githunguri Water and Sanitation Company Ltd.</h3>
                  <hr />
                  <Button title="How may we Help You?" action={ () => setToggleQuickServices(true)} color="white"/>
              </div>
          </div>} */}
      </div>
      <div className="main1">
        <div className="main1bg">
          <div className="section1">
                <div className="intro">
                    <h2 className="color-animation">Thank you for visiting Githunguri Water! </h2>
                    <p>
                    At Githunguri Water and Sanitation Company, we are committed to providing clean, safe, and reliable water 
                    services to the residents of Githunguri and its surrounding areas. Our dedication to excellence ensures that 
                    we meet the water needs of our community with efficiency and sustainability. From advanced water treatment 
                    processes to effective distribution systems, we strive to deliver services that enhance the quality of life for 
                    our customers. </p>
                    <p>We understand the critical role that water plays in our daily lives, and we take pride in being 
                    a trusted partner in the community’s development. At Githunguri Water and Sanitation Company, every drop counts, 
                    and we are dedicated to managing this precious resource responsibly, ensuring that Githunguri thrives now and in the future.</p>
                </div>
          </div>
          <div className="section2" id="services">
              <div className="centerheading">
                <h2 className="decorated-heading">Our Services</h2>
              </div>
              <div className="whatwedo">
                <div className="service">
                  <FontAwesomeIcon className="index" icon={faFaucetDrip} />
                  <h3>New Water Connection</h3>
                </div>
                <div className="service">
                <FontAwesomeIcon className="index" icon={faSync} />
                  <h3>Water Reconnection</h3>
                </div>
                <div className="service">
                <FontAwesomeIcon className="index" icon={faTools} />
                  <h3>Operation & Maintenance</h3>
                </div>
                <div className="service">
                <FontAwesomeIcon className="index" icon={faHeadset} />
                  <h3>Customer Service</h3>
                </div>
              </div>

              <div className="wasrebkpis">
                <div className="items">
                  <div className="kpi">
                    <h3>Supply Hours</h3>
                    <p>24hrs</p>
                  </div>
                  <div className="kpi">
                    <h3>Non Revenue Water</h3>
                    <p>67%</p>
                  </div>
                  <div className="kpi">
                    <h3>Water Coverage</h3>
                    <p>25%</p>
                  </div>
                  <div className="kpi">
                    <h3>Drinking Water Quality</h3>
                    <p>100%</p>
                  </div>
                  <div className="kpi">
                    <h3>Meter Coverage</h3>
                    <p>60%</p>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div className="main2" id="why">
        <div className="main2bg">
          <div className="whyus">
              <div className="centerheading">
                <h2  className="decorated-heading">Our Core Values</h2>
              </div>
              <p>At Githunguri Water and Sanitation Company, we are committed to delivering reliable and innovative water solutions that enhance the quality of life for our community. Discover why partnering with us ensures access to top-notch water services:</p>
              <div className="container">
                <div className="reason data">
                  <h3>Strategic Innovation</h3>
                  {/* <p>Our team consists of highly skilled professionals with expertise in various technologies and domains. We have years of experience delivering successful projects for clients across industries.</p> */}
                </div>
                <div className="reason nodata"></div>
                <div className="reason data">
                  <h3>Customer Focus</h3>
                </div>
                <div className="reason nodata"></div>
                <div className="reason data">
                  <h3>Good Governance</h3>
                </div>
                <div className="reason nodata"></div>

                <div className="reason data">
                  <h3>Time</h3>
                  {/* <p>We stay ahead of the curve by embracing the latest trends and innovations in technology. From AI and machine learning to blockchain and IoT, we leverage cutting-edge tools and methodologies to drive innovation and create value for our clients.</p> */}
                </div>
                <div className="reason nodata"></div>
                <div className="reason data">
                  <h3>Teamwork</h3>
                </div>
                <div className="reason nodata"></div>
                <div className="reason data">
                  <h3>Integrity</h3>
                </div>
                <div className="reason nodata"></div>
                <div className="reason nodata"></div>
                <div className="reason nodata"></div>
                <div className="reason data">
                  <h3>Climate Adaptation</h3>
                </div>
                <div className="reason nodata"></div>
                {/* <div className="reason data extra-item">
                  <h3>Teamwork</h3>
                </div> */}
                {/* <div className="reason data">
                  <h3>Teamwork</h3>
                </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="main3" id="news">
        <div className="main3bg">
            <div className="centerheading">
              <h2  className="decorated-heading">News and Events</h2>
            </div>
            <div className="news">
              <PostItem image={cis} title="Customer Identification Survey" details="We are currently conducting a Customer Identification Survey to enhance our services. By updating your details, you help us respond quickly to your needs and improve service reliability." date="Ongoing" />
              {/* <PostItem image={waterinterruptiongatamaiyu} title="Temporary Water Supply Interruption" details="We regret to inform residents of <b>Kamunyuini in Nyanduma Ward </b> that there is temporary water supply interruption due to the ongoing mau-mau road works affecting the entire <b>Kagwe, Kiambururu, Mitundu, Kamuchege, Miirano </b> and surrounding areas. <br/><br/>Our technical team is currently on-site working to restore the supply as quickly as possible.
We apologize for the inconvenience and appreciate your patience as we work to resolve this issue. <br/><br/>Further updates will be provided as soon as possible.
<br/>Thank you for your understanding." date="26 Oct 2024" /> */}
              <PostItem 
                image={waterrestoredgatamaiyu} 
                title="Water Supply Restored" 
                details="We are pleased to inform residents of <b>Kamunyuini in Nyanduma Ward</b> that the water supply has been restored following the resolution of issues caused by the ongoing mau-mau road works. <br/><br/>Our technical team has worked diligently to ensure water services are back to normal for all affected areas, including <b>Kagwe, Kiambururu, Mitundu, Kamuchege, Miirano</b>, and surrounding regions. <br/><br/>We appreciate your patience and understanding during this time and thank you for your continued support. 
              <br/>For further updates, please stay connected with us." 
                date="29 Oct 2024" 
              />
              <PostItem image={pe} title="Public Engagement" details="We will be conducting a public engagement at Thakwa. All stakeholders and our esteemed customers around Thakwa are invited to give their suggestions to help us respond quickly to your needs and improve service reliability." date="Sunday, 20th Oct 2024" />
              
            </div>
            <br />
            <div className="allnews">
              <Button title="All News >>" action={() =>window.location.href="/newsandevents"} color="blue"/>
            </div>
        </div>
      </div>
      <div className="main4" id="partners">
        <div className="main4bg">
            <div className="centerheading">
              <h2  className="decorated-heading">Our Partners</h2>
            </div>
            <div className="partnerss">
              <PartnerCarousel />
            </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
}