import { useEffect, useState, useRef } from "react";
import "../styles/stafflogin.scss";
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import Button from "../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import PostItem from "../components/post_item";
import allstaffmeeting from "../assets/imgs/staffmeeting.jpeg"
import UserInput from "../components/UserInput";

export default function StaffLogin (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);

  const rfEmail = useRef();
  const rfPassword = useRef();
  // const [error, setError] = useState("");
  const [error, setError] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [body, updateBody] = useState({
    Email: null,
    Password: null,
  });
  const ref = useRef();
  const [toggleForgot, setToggleForgot] = useState(false);

  const login = () => {
    let d = body;
    d.Email = rfEmail.current.getValue().toLowerCase().trim();
    d.Password = rfPassword.current.getValue();
    updateBody(d);
    setError("");

    if (!validateEmail(body.Email))
      return setError("Please Enter a Valid Email Address!");
    if (!validatePassword(body.Password))
      return setError("Password must be at least 6 characters!");

    if (validateEmail(body.Email) && validatePassword(body.Password)) {
      console.log(JSON.stringify(body));
      
      setIsLoading(true);
      fetch("/api/auth/login", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          } else {
            throw Error("Login Failed");
          }
        })
        .then((data) => {
          console.log(data);
          
          if (data.success) {
            localStorage.setItem("nimda_giwasco", data.token);
            setError(data.success);
            setIsLoading(false);
            window.location.href = "/admin";
          } else {
            setIsLoading(false);
            setError(data.error);
          }
        })
        .catch((err) => {
          console.log(err);
          
          setIsLoading(false);
          setError("Login failed");
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };
  
  return (
    <div className="body">
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="login-page">
        <div className="login-container">
          <h2>Login</h2>
          <form onSubmit={(e) => {
              e.preventDefault();
            }}>
              <UserInput ref={rfEmail} label="Email" type="email" id="email" placeholder="Enter your email" />
              <UserInput ref={rfPassword} label="Password" type="password" id="password" placeholder="Enter your password" />
            <h6>{error}</h6>
            <button type="submit" className="login-btn" onClick={() => login()}>
              Login
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}