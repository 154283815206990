import "../styles/singleservicelink.scss";
import team from "../assets/imgs/GIWASCO-team.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default function SingleServiceLink (props) {

    return (
        <div className="singleservicelink" onClick={() => window.location.href= props.page}>
            <div className="slcontent">
                <img src={props.icon} alt="" />
                {/* <FontAwesomeIcon className="index" icon={props.icon} /> */}
                <h5>{props.text}</h5>
            </div>
        </div>         
    );
}