import "../styles/not_found.scss";

export default function NotFound (props) {
    return (
        <div className="notfound">
            <h1>404</h1>
            <p>Page not found</p>
            <p>Sorry, This page does not exist.</p>
            <p>Githunguri Water and Sanitation Company Ltd.</p>
            <div className="back">
                <a href="/">Back Home</a>
                <a href="/#">Contact Us</a>
            </div>
        </div>
    );
}