import React from "react";
import "../../styles/footer.scss"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck } from '@fortawesome/free-solid-svg-icons';
import { faFacebookSquare, faXTwitter, faInstagramSquare, faYoutubeSquare, faLinkedinIn, faInstagram, faFacebook, faYoutube, } from "@fortawesome/free-brands-svg-icons";
import logo from "../../assets/imgs/giwasco_logo.png";
import QuickLinks from "../quick_links";

export default function StaffFooter(props) {
    return(
        <div className="footer" id="footer">
            <div className="content">
                {/* <QuickLinks /> */}
                <div className="socials">
                    <FontAwesomeIcon icon={faEnvelope} className="socialicon" onClick={() => window.location.href="mailto:enquiries@githunguriwater.co.ke"}/>
                    <FontAwesomeIcon icon={faFacebook} className="socialicon" onClick={() => window.location.href="https://www.facebook.com/githunguriwater/"}/>
                    <FontAwesomeIcon icon={faXTwitter} className="socialicon" onClick={() => window.location.href="https://twitter.com/githunguriwater"}/>
                    <FontAwesomeIcon icon={faInstagram} className="socialicon" onClick={() => window.location.href="https://www.instagram.com/githunguriwater/"}/>
                    <FontAwesomeIcon icon={faYoutube} className="socialicon" onClick={() => window.location.href="https://www.youtube.com/@githunguriwater"}/>
                    <FontAwesomeIcon icon={faLinkedinIn} className="socialicon"/>
                </div>
                <div className="logo">
                    <img src={logo} alt="" onClick={() => window.location.href = "https://githunguriwater.co.ke"}/>
                </div>
                <div className="legal">
                    {/* <div className="terms">
                        <p>Terms & Conditions</p>
                        <div className="dot"></div>
                        <p>Privacy Policy</p>
                    </div> */}
                    © {new Date().getFullYear()} Githunguri Water and Sanitation Company Ltd. All rights reserved.<br />
                </div>
            </div>
        </div>
    );
}