import { useEffect, useState, useRef } from "react";
import "../../styles/stafflogin.scss";
import Header from "../../components/header";
import Footer from "../../components/footer"
import MenuTab from "../../components/menu_tab";
import Banner from "../../components/banner";
import Button from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import PostItem from "../../components/post_item";
import allstaffmeeting from "../../assets/imgs/staffmeeting.jpeg"
import UserInput from "../../components/UserInput";
import UserSelect from "../../components/UserSelect";
import StaffHeader from "../../components/staff/staffheader";
import StaffFooter from "../../components/staff/stafffooter";

export default function PostNewCareer (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);

  const [selectedFile, setSelectedFile] = useState(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const position = useRef();
  const type = useRef();
  const grade = useRef();
  const department = useRef();
  const description = useRef();
  const dateOpened = useRef();
  const deadline = useRef();
  const fileRef = useRef();

  const [body, updateBody] = useState({
    Title: null,
    Type: null,
    Grade: null,
    Description: null,
    DateOpened: null,
    Deadline: null,
    File: null,
  });

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const validateForm = () => {
    let result = true;
    let d = body;
    d.Position = position.current.getValue();
    d.Type = type.current.getValue();
    d.Grade = grade.current.getValue();
    d.Department = department.current.getValue();
    d.Description = description.current.getValue();
    d.DateOpened = dateOpened.current.getValue();
    d.Deadline = deadline.current.getValue();
    d.File = fileRef.current.files[0];

    updateBody(d);
    setError("");

    if (!body.Position) {
      result = false;
      setError("Position cannot be blank!");
      return result;
    }

    if (!body.Description || body.Description.length < 10) {
      result = false;
      setError("Enter a sufficient description!");
      return result;
    }
    if (!body.File) {
      result = false;
      setError("Upload a file to publish!");
      return result;
    }
    if (!body.DateOpened) {
      result = false;
      setError("Enter a open date of the job posting!");
      return result;
    }

    if (!body.Deadline) {
      result = false;
      setError("Enter a valid deadline the job posting!");
      return result;
    }

    return result;
  };

  const createAdvert = () => {
    if (validateForm()) {

    const formData = new FormData();

    for (const key in body) {
      if (body.hasOwnProperty(key) && body[key] !== undefined && body[key] !== null) {
        formData.append(key, body[key]);
      }
    }

    // Debugging
    for (let pair of formData.entries()) {
      console.log(`${pair[0]}: ${pair[1]}`);
    }

    console.log(body);
    
    console.log(formData);
    setLoading(true);
    fetch(`/api/careers/create`, {
      method: "POST",
      credentials: "include",
      headers: {
        // "Content-Type": "application/json", Never have this when sending files
        Accept: "application/json",
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else throw Error("");
      })
      .then((data) => {
        console.log(data);
        
        setLoading(false);
        if (data.success) {
          setError(data.success);
          setTimeout(() => {
            window.location.href = "/admin/careers";
          }, 1500);
        } else {
          setError(data.error);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
    }
  };
  
  return (
    <div className="body">
      <Banner />
      <StaffHeader toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="login-page">
        <div className="login-container">
          <h2>Post New Career</h2>
          <form onSubmit={(e) => {
              e.preventDefault();
            }}>
            <UserInput ref={position} label="Job Title" type="text" id="position" placeholder="Enter job position" />
            <UserSelect ref={type} label="Type" data = {["Contract", "Permanent & Pensionable", "Casual", "Internship", "Attachment"]} />
            <UserSelect ref={grade} label="Grade" data = {["1", "2", "3", "4", "5", "6", "7"]} />
            <UserSelect ref={department} label="Department" data = {["Commercial", "Financial", "HR/Admin", "Procurement", "Technical", ]} />
            <UserInput ref={description} label="Job Description" type="textarea" id="description" placeholder="Enter job description" />
            
    
            <UserInput ref={dateOpened} label="Date Opened" type="date" id="date" placeholder="Enter date opened" />
            <UserInput ref={deadline} label="Deadline" type="date" id="date" placeholder="Enter deadline" />
            <div className="form-group">
            <label>Add advert file</label>
              <input
                id="file"
                ref={fileRef}
                type="file"
                onChange={(e) => setSelectedFile(e.target.files[0])}
                accept=".pdf, .png, .jpg, .jpeg"
              />
            </div> 
            <h6>{error}</h6>
            <button type="submit" className="login-btn" onClick = { () => createAdvert()} >
              Post Advert
            </button>
          </form>
        </div>
      </div>
      <StaffFooter />
    </div>
  );
}