import officerImage from '../../assets/imgs/management/gis.jpg';

export default function GISBio (props) {

    return (
        <div className="bio-container">
            <div className="bio-card">
                <div className="bio-image">
                <img src={officerImage} alt="Officer Name" />
                </div>
                <div className="bio-content">
                    <h2>James Mwenda</h2>
                    <h3>GIS & ICT</h3>
                    <hr />
                    <div className="bio-details">
                        <p>MSc. Data Science, BTech. Geospatial Information Technology (TUK).</p>
                        {/* <h4>Education</h4>
                        <ul>
                            <li>MSc in Data Science - KCA University</li>
                            <li>BTech in Geo-Information Technology - The Technical University of Kenya.</li>
                        </ul> */}
                    </div>
                    <p>
                        James Mwenda is a <b>technology and data-driven professional</b> with a strong foundation in Geospatial Information Technology
                        and an evolving specialization in Data Science. His work revolves around applying cutting-edge GIS
                        and spatial data analysis techniques to solve real-world problems, particularly in the fields of water and
                        sanitation services.
                    </p>
                    <p>
                        With a deep understanding of remote sensing, GIS development, and spatial database management, 
                        he has contributed to projects that integrate innovative solutions such as geospatial analytics dashboards and methodologis for 
                        enhanced data visualization and management. His expertise also spans GIS and remote sensing software such as 
                        QGIS, ArcGIS, ERDAS Imagine and AutoCAD.
                    </p>
                    <p>
                        Currently pursuing his master's degree, James focuses on bridging the gap between GIS and data science, 
                        working on research that integrates spatial analysis with modern machine learning techniques to 
                        provide insights and <b>solutions in water resource management</b>. His interests lies in using GIS for 
                        water governance, sanitation services, and addressing climate adaptation challenges.
                    </p>
                    <p>
                        At Githunguri Water and Sanitation Company, he leads <b>data collection efforts, GIS technology adaptation and integration,
                        map production, and analysis of water systems</b>. His work emphasizes strategic innovation and 
                        ensuring that GIS technologies are effectively used across departments for decision-making, sustainable management, 
                        and improving water services across the region. James is also dedicated to creating platforms for community participation in water resource management, 
                        utilizing GIS tools for climate adaptation and resilience in the face of increasing water scarcity and 
                        climate change.
                    </p>
                    <hr />
                </div>
            </div>
        </div>     
    );

    
}