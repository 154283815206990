import "../styles/button.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faMoon, faSun, faX} from "@fortawesome/free-solid-svg-icons";
import logo from "../assets/imgs/hexlogosinpalabrascrop.png"
import { useState } from "react";
import QuickLinks from "./quick_links";

export default function Button (props) {

    return (
        <div className= {props.color == "blue" ? "button-blue" : "button-white" } onClick={() => {props.action()}}>
            <p>{props.title}</p>
        </div>
    );
}