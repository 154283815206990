import { useEffect, useState } from "react";
import "../../styles/administration.scss";
import Header from "../../components/header";
import Footer from "../../components/footer";
import MenuTab from "../../components/menu_tab";
import Banner from "../../components/banner";
import Button from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import PostItem from "../../components/post_item";
import allstaffmeeting from "../../assets/imgs/staffmeeting.jpeg"
import StaffHeader from "../../components/staff/staffheader";
import StaffFooter from "../../components/staff/stafffooter";

export default function Administration (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);
  
  return (
    <div className="body">
      <Banner />
      <StaffHeader toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="careers" id="careers">
        <div className="centerheading">
          <h2 className="decorated-heading">Administration</h2>
        </div>
        <hr />
        <div className="centertext">
          <div className="centerheading">
            <h3 className="heading">This is the Admin Page</h3>
          </div>
          <p>At the moment, there are nothing to show here. Please check back regularly for updates.</p>
          <p>Thank you for your resilient efforts in making Githunguri Water Great!</p>
        </div>
        <hr />
      </div>
      <Footer />
    </div>
  );
}