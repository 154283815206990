import { useEffect, useState } from "react";
import "../styles/newsevents.scss"
import Header from "../components/header";
import Footer from "../components/footer"
import MenuTab from "../components/menu_tab";
import Banner from "../components/banner";
import PostItem from "../components/post_item";
import newconnapplicationform from "../assets/docs/newconnectionform.pdf";
import cis from "../assets/imgs/cis.png";
import pe from "../assets/imgs/publicparticipation.jpeg";

export default function Gallery (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);

  // const downloadFile = () => {
  //   // Create a temporary link element
  //   const link = document.createElement('a');
  //   link.href = "/newconnectionform.pdf"; // Path to the file in the public folder
  //   link.download = 'application-form.pdf'; // Filename for the downloaded file
  //   document.body.appendChild(link); // Append link to the body
  //   link.click(); // Programmatically click the link to trigger the download
  //   document.body.removeChild(link); // Remove the link from the document
  // };

  const openFile = () => {
    const fileUrl = newconnapplicationform; // Path to the file in the public folder
    window.open(fileUrl, '_blank'); // Open the file in a new tab
  };
  
  return (
    <div className="body">
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="image2">
        <div id="newconnection">
          <p>Gallery</p>
        </div>
      </div>
      <div className="gallery" id="gallery">
        <div className="centerheading">
        <h5  className="decorated-heading">Be on the know...</h5>
        </div>
      </div>
      <Footer />
    </div>
  );
}