import React, { useState, useEffect } from 'react';
import '../styles/partnercarousel.scss'; // Your custom styles

// Importing logos
import kiambucounty from "../assets/imgs/partners/kiambucounty.jpeg";
import ministryofwater from "../assets/imgs/partners/ministryofwater.jpeg";
import athiwater from "../assets/imgs/partners/athiwater.jpeg";
import wasreb from "../assets/imgs/partners/wasreb.png";
import waterresourcesauthority from "../assets/imgs/partners/waterresourcesauthority.png";
import wstf from "../assets/imgs/partners/wstf.png";
import kebs from "../assets/imgs/partners/Kebs.jpg";
import danida from "../assets/imgs/partners/Danida-English.jpg"

const PartnerCarousel = () => {
  const partners = [kiambucounty, ministryofwater, athiwater, wasreb, waterresourcesauthority, wstf, kebs, danida];
  const repeatedLogos = [...partners, ...partners, ...partners, ...partners, ...partners, ...partners]; // Repeating logos to create endless effect

  const [index, setIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(4); // Default to 4 logos visible at once

  // Auto-scroll every 3 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 3000);
    return () => clearInterval(interval); // Clear interval on unmount
  }, [index]);

  // Handle resizing to update the number of visible items
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setVisibleItems(1); // Show 1 logo on small screens
      } else if (window.innerWidth < 1024) {
        setVisibleItems(2); // Show 2 logos on medium screens
      } else {
        setVisibleItems(4); // Show 4 logos on large screens
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial state based on current screen size

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNext = () => {
    setIndex((prevIndex) => (prevIndex + 1) % repeatedLogos.length);
  };

  const handlePrev = () => {
    setIndex((prevIndex) => (prevIndex - 1 + repeatedLogos.length) % repeatedLogos.length);
  };

  return (
    <div className="partner_carousel-container">
      <button className="partner_carousel-arrow left-arrow" onClick={handlePrev}>
        &lt;
      </button>
      <div className="partner_carousel">
        <div
          className="partner_carousel-inner"
          style={{
            transform: `translateX(-${(index % repeatedLogos.length) * (100 / visibleItems)}%)`,
            width: `${repeatedLogos.length * 100 / visibleItems}%`, // Adjust container width based on visible items
          }}
        >
          {repeatedLogos.map((logo, i) => (
            <div key={i} className="partner_carousel-item" style={{ width: `${100 / visibleItems}%` }}>
              <img src={logo} alt={`Partner logo ${i + 1}`} />
            </div>
          ))}
        </div>
      </div>
      <button className="partner_carousel-arrow right-arrow" onClick={handleNext}>
        &gt;
      </button>
    </div>
  );
};

export default PartnerCarousel;
