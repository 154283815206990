import officerImage from '../../assets/imgs/management/MD.jpg';

export default function MDBio (props) {

    return (
        <div className="bio-container">
            <div className="bio-card">
                <div className="bio-image">
                <img src={officerImage} alt="Officer Name" />
                </div>
                <div className="bio-content">
                    <h2>Francis Kahuha</h2>
                    <h3>Managing Director</h3>
                    <hr />
                    <div className="bio-details">
                        <p>Francis Kahuha PhD, MSc, (University of Nairobi) BSc. IBA (USIU-Africa)</p>
                        {/* <h4>Education</h4>
                        <ul>
                            <li>PhD. in Environmental Governance and Management - University of Nairobi</li>
                            <li>MSc in Environmental Governance - University of Nairobi</li>
                            <li>BSc in International Business Administration - United States International University- Africa.</li>
                        </ul> */}
                    </div>
                    <p>
                        Francis Kahuha is currently the Managing Director at Githunguri Water and Sanitation
                        Company. An interdisciplinary distinguished scholar, leader, author and visiting lecturer at the University of
                        Nairobi who focuses on atmospheric science, hydro informatics, waste water management, the energy
                        transition, climate change attribution, pollution control, earth systems modelling, development of early
                        warning systems, community based governance, climate policy, socioecological
                        resilience, rural livelihoods, water economics and water governance.
                    </p>
                    <p>
                        Born in Kiambu County, his parents moved to
                        Laikipia County where he attained his early education at Nyakinyua Primary School in
                        Laikipia West and Nanyuki Boys High School in Laikipia East. He then proceeded to
                        United States International University for his Bachelor’s Degree in International
                        Business Administration and University of Nairobi for both Masters of Science and
                        Doctorate Studies in Environmental Governance and Management at the department of
                        Earth and Climate Sciences. He also holds a Certificate in Sustainable Land Use
                        Management (SLUSE) from the department of food and resource economics, University
                        of Copenhagen.
                    </p>
                    <p>
                        With over ten years of experience in the field, he is dedicated to
                        advancing effective governance frameworks that address pressing environmental
                        challenges at local, national, and international levels particularly improving water
                        governance frameworks, ensuring equitable water access, climate resilience building
                        and promoting integrated water resource management (IWRM) across regions. 
                    </p>
                    <p>
                        In his current position as the Managing Director for Githunguri Water, Francis is working with
                        government agencies, non-governmental organizations, international bodies,
                        development partners, advising on water policy reforms and capacity-building initiatives
                        as well as managing day to day operations of the company. A strong advocate for
                        community participation and climate adaptation mainstreaming in water governance and
                        management, Francis has facilitated multi-stakeholder dialogues, collaborated with
                        diverse communities, published in peer reviewed journals and developed strategies to
                        bridge the gap between policy and practice.
                    </p>
                    <p>
                        Francis has been at the forefront of
                        research on key topics like global warming and temperature rise, energy transition and
                        decarbornization, ocean acidification, cryosphere changes, extreme weather events,
                        climate modelling and prediction, climate change adaptation, cloud formation and
                        microphysics together with investigating long-range interactions like the El Niño-
                        Southern Oscillation (ENSO), the North Atlantic Oscillation (NAO), and the Pacific
                        Decadal Oscillation (PDO) that influence climate variability. Francis loves traveling to
                        diverse regions to observe different climates, biomes, and ecosystems, from polar
                        regions to rainforests, to understand climate change impacts firsthand. He is a science-
                        curious person who pays close attention to details, as small observations can often lead
                        to significant discoveries.
                    </p>
                    <hr />
                    {/* <div className="bio-details">
                        <h4>Achievements</h4>
                        <ul>
                        <li>Transformed the company to achieve 30% increase in efficiency.</li>
                        <li>Successfully led the implementation of the climate adaptation strategy.</li>
                        <li>Recognized for excellent customer service initiatives.</li>
                        </ul>
                        <h4>Contact</h4>
                        <ul>
                        <li>Email: johndoe@company.com</li>
                        <li>LinkedIn: linkedin.com/in/johndoe</li>
                        </ul>
                    </div> */}
                </div>
            </div>
        </div>     
    );

    
}