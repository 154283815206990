import { useEffect, useState, useRef } from "react";
import { jwtDecode } from 'jwt-decode';
import "../../styles/stafflogin.scss";
import Header from "../../components/header";
import Footer from "../../components/footer"
import MenuTab from "../../components/menu_tab";
import Banner from "../../components/banner";
import Button from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import UserInput from "../../components/UserInput";

export default function ChangeStaffUserPW (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [user, setUser] = useState();
  const [username, setUsername] = useState();


  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("nimda_giwasco");

    if (token) {
      var decoded = jwtDecode(token);
      setUser(decoded.UserID);
      setUsername(decoded.Name)
    } else {
      setUser();
    }
  }, []);

  const curpassword = useRef();
  const newpassword = useRef();
  const cnewpassword = useRef();


  const changePassword = () => {
    const body = {
      Password: curpassword.current.getValue(),
      NewPassword: newpassword.current.getValue(),
      CNewPassword: cnewpassword.current.getValue(),
    };

    setError("");

    // const validateForm = () => {
    //   let result = true;
    //   if (
    //     !confirmPassword(body.NewPassword, body.CNewPassword)
    //   ) {
    //     result = false;
    //     setError("Password must be at least 6 characters!");
    //     setLoading(false);
    //     return result;
    //   }
    //   if (
    //     !validatePassword(body.Password)
    //   ) {
    //     result = false;
    //     setError("Password must be at least 6 characters!");
    //     setLoading(false);
    //     return result;
    //   }
    //   return result;
    // };

    const validateForm = () => {
      let result = true;
      let errorMessage = "";
  
      // Check if the new password and confirm password match
      if (!confirmPassword(body.NewPassword, body.CNewPassword)) {
          result = false;
          errorMessage = "Passwords do not match!";
          setError(errorMessage);
          setLoading(false);
          return result;
      }
  
      // Validate the new password strength
      // if (!validatePassword(body.NewPassword)) {
      //     result = false;
      //     setError(errorMessage || "Password must be at least 12 characters long, contain uppercase and lowercase letters, numbers, and at least 2 special characters (e.g., !@#$%^&*).");
      //     setLoading(false);
      //     return result;
      // }
  
      return result;
  };
  
  // Password validation function
  const validatePassword = (password) => {
      const minLength = 12;
      const specialChars = /[!@#$%^&*(),.?":{}|<>]/g;
      const hasUppercase = /[A-Z]/.test(password);
      const hasLowercase = /[a-z]/.test(password);
      const hasNumber = /\d/.test(password);
      const hasSpecialChars = password.match(specialChars) && password.match(specialChars).length >= 2;
  
      // Check all conditions and build error messages
      if (password.length < minLength) {
          setError("Password must be at least 12 characters long.");
          return false;
      }
      if (!hasUppercase) {
          setError("Password must contain at least one uppercase letter.");
          return false;
      }
      if (!hasLowercase) {
          setError("Password must contain at least one lowercase letter.");
          return false;
      }
      if (!hasNumber) {
          setError("Password must contain at least one number.");
          return false;
      }
      if (!hasSpecialChars) {
          setError("Password must contain at least 2 special characters (e.g., !@#$%^&*).");
          return false;
      }
      
      // All conditions met
      return true;
  };
  

    if (validateForm() && validatePassword(body.NewPassword)) {
      setLoading(true);
      fetch(`/api/auth/${user}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          console.log(data);
          
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              window.location.href = "/stafflogin";
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const confirmPassword = (password, cpassword) => {
    return cpassword === password;
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  
  return (
    <div className="body">
      <Banner />
      <Header toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="login-page">
        <div className="login-container">
          <h2>Change Password for {username}</h2>
          <form onSubmit={(e) => {
              e.preventDefault();
            }}>
            <UserInput ref={curpassword} label="Old Password" type="password" id="password" placeholder="Enter your old password" />
            <UserInput ref={newpassword} label="New Password" type="password" id="passwordE" placeholder="Enter your new password" />
            <UserInput ref={cnewpassword} label="Confirm New Password" type="password" id="cnewpassword" placeholder="Re-enter your new password" />
            <h6>{error}</h6>
            <button type="submit" className="login-btn" onClick = { () => changePassword()} >
              Submit
            </button>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}