import { useEffect, useState, useRef } from "react";
import "../../styles/stafflogin.scss";
import Header from "../../components/header";
import Footer from "../../components/footer"
import MenuTab from "../../components/menu_tab";
import Banner from "../../components/banner";
import Button from "../../components/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faX, faIns, faLocation, faLocationPin, faPhone, faUserCheck, faWater, faPlug, faTools, faHeadset, faTachometer, faTachometerAlt, faFaucet, faFaucetDrip, faSync } from '@fortawesome/free-solid-svg-icons';
import PostItem from "../../components/post_item";
import allstaffmeeting from "../../assets/imgs/staffmeeting.jpeg"
import UserInput from "../../components/UserInput";
import UserSelect from "../../components/UserSelect";
import StaffHeader from "../../components/staff/staffheader";
import StaffFooter from "../../components/staff/stafffooter";

export default function PostNewMedia (props) {
  const [toggleMenu, setToggleMenu] = useState(false);
  const [toggleQuickServices, setToggleQuickServices] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const name = useRef();
  const gender = useRef();
  const email = useRef();
  const phone = useRef();
  const position = useRef();
  const department = useRef();
  const role = useRef();
  const password = useRef();
  const cpassword = useRef();

  function titleCase(str) {
    var splitStr = str.toLowerCase().split(" ");
    for (var i = 0; i < splitStr.length; i++) {
      splitStr[i] =
        splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(" ");
  }

  const createUser = () => {
    const body = {
      Name: titleCase(name.current.getValue().trim()),
      // Gender: gender.current.getValue(),
      Phone: phone.current.getValue(),
      Email: email.current.getValue().toLowerCase().trim(),
      Department: titleCase(department.current.getValue()),
      Position: titleCase(position.current.getValue()),
      Password: password.current.getValue(),
      CPassword: cpassword.current.getValue(),
      Role: role.current.getValue(),
    };

    setError("");

    const validateForm = () => {
      let result = true;
      if (!validateEmail(body.Email)) {
        result = false;
        setError("Please Enter a valid email address!");
        setLoading(false);
        return result;
      }
      if (
        !confirmPassword(body.Password, body.CPassword)
      ) {
        result = false;
        setError("Password must be at least 6 characters!");
        setLoading(false);
        return result;
      }
      if (
        !validatePassword(body.Password)
      ) {
        result = false;
        setError("Password must be at least 6 characters!");
        setLoading(false);
        return result;
      }
      if (!body.Phone || body.Phone.length !== 10) {
        result = false;
        setError("Enter a valid phone number");
        setLoading(false);
        return result;
      }
      if (!body.Name) {
        result = false;
        setError("Name is reqired!");
        setLoading(false);
        return result;
      }
      if (!body.Department) {
        result = false;
        setError("Department field is required!");
        setLoading(false);
        return result;
      }
      if (!body.Position) {
        result = false;
        setError("Position field is required!");
        setLoading(false);
        return result;
      }
      if (!body.Role) {
        result = false;
        setError("Role is required!");
        setLoading(false);
        return result;
      }
      return result;
    };

    if (validateForm()) {
      setLoading(true);
      fetch(`/api/auth/register`, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(body),
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else throw Error("");
        })
        .then((data) => {
          console.log(data);
          
          setLoading(false);
          if (data.success) {
            setError(data.success);
            setTimeout(() => {
              window.location.href = "/stafflogin";
            }, 1000);
          } else {
            setError(data.error);
          }
        })
        .catch((err) => {
          setLoading(false);
        });
    }
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const confirmPassword = (password, cpassword) => {
    return cpassword === password;
  };

  const validatePassword = (password) => {
    return password.length >= 6;
  };

  
  return (
    <div className="body">
      <Banner />
      <StaffHeader toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />
      {toggleMenu && <MenuTab toggleMenu={toggleMenu} 
        setToggleMenu={setToggleMenu}
      />}
      <div className="login-page">
        <div className="login-container">
          <h2>Post New Media</h2>
          <form onSubmit={(e) => {
              e.preventDefault();
            }}>
            <UserInput ref={name} label="Full Name" type="text" id="name" placeholder="Enter your name" />
            <UserInput ref={email} label="Email" type="email" id="email" placeholder="Enter your email" />
            <UserInput ref={phone} label="Phone" type="phone" id="phone" placeholder="Enter your phone number" />
            <UserInput ref={position} label="Position" type="text" id="position" placeholder="Enter your position" />
            <UserSelect ref={department} label="Department" data = {["Commercial", "Financial", "HR/Admin", "Procurement", "Technical", ]} />
            <UserSelect ref={role} label="Role" data = {["Admin", "IT", "HR", "Procurement", "Communication"]} />
            <UserInput ref={password} label="Password" type="password" id="password" placeholder="Enter your password" />
            <UserInput ref={cpassword} label="Confirm Password" type="password" id="password" placeholder="Re-nter your password" />
            <h6>{error}</h6>
            <button type="submit" className="login-btn" onClick = { () => createUser()} >
              Login
            </button>
          </form>
        </div>
      </div>
      <StaffFooter />
    </div>
  );
}